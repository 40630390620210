import { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeMixinsAPACLW, buildThemeWidgetStylesAPACLW, buildThemeVarsAPACLW } from 'dw-apac-law/theme';

const createTheme: ThemeCreateFunction = () =>
  buildTheme(
    (vars: Pick<Theme, CommonThemeVars>) => {
      return merge({}, buildThemeVarsAPACLW(vars));
    },
    (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
      return buildThemeMixinsAPACLW(vars);
    },
    (vars: Omit<Theme, 'widgets'>) => {
      const buildThemeWidget = buildThemeWidgetStylesAPACLW(vars);
      return merge({}, buildThemeWidget, {
        Footer: {
          navItems: `
            ${vars.mixins.footerNavItems}
            ul {
              margin: 12px 0 0 20px !important; 
              ${vars.breakpoints.lg} {
                margin: 12px 0 0 0px !important;
              }
              li {
                line-height: 35px;
              }
            }
          `,
        },
      });
    },
  );

export default createTheme;
