/**
 * Theme for Laithwaites AU site
 */
import { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeMixinsLW, buildThemeVarsLW, buildThemeWidgetStylesLW } from 'dw-uk-law/theme';

export const buildThemeVarsAPACLW = (vars: Pick<Theme, CommonThemeVars>) => {
  const lwPink500 = '#E2004D';
  const lwGrey700 = '#616161';
  const lwUnlimitedMemberPrice = '#009F8E';
  const lwJoinWhite = '#FFFFFF';
  const lwBodyBlack = '#222222';

  const colors = {
    textPrimaryBg: lwPink500,
    textSecondaryBg: lwGrey700,
    textUnlimitedPrice: lwUnlimitedMemberPrice,
    lwJoinWhite: lwJoinWhite,
    lwBodyBlack: lwBodyBlack,
  };
  const fonts = {
    sizeH5: '18px',
  };

  const lwThemeVars = buildThemeVarsLW(vars);

  return merge({}, lwThemeVars, {
    meta: {
      name: 'Laithwaites AU',
    },
    icons,
    colors,
    fonts,
  });
};
export const buildThemeMixinsAPACLW = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  return buildThemeMixinsLW(vars);
};
export const buildThemeWidgetStylesAPACLW = (vars: Omit<Theme, 'widgets'>) => {
  const buildThemeWidget = buildThemeWidgetStylesLW(vars);
  return merge({}, buildThemeWidget, {
    Search: {
      hitWrapper: ` 
      ${buildThemeWidget.Search.hitWrapper}
      &.grid-view { 
        .ais-Hits-list {
          .ais-Hits-item {
            .image-bg {
              height: 244px;
              display: flex;
              flex-direction: column;
              padding: 0;

              .image-container {
                padding: 12px 16px;
                position: relative;
                height: 100%;

                .image {
                  margin: 0;
                  img {
                    position: relative !important;
                    height: 220px !important;
                  }
                }

                .offer {
                  display: flex;
                  flex-direction: column;
                  position: absolute;
                  left: 16px;
                  top: 12px;
                  bottom: 12px;
                  svg {
                    top: 0;
                    left: 0
                  }

                  .stock {
                    z-index: 1;
                    left: -6px;
                    bottom: 0;
                    width: 77px;
                    ${vars.breakpoints.xxl} {
                      left: 0;
                    }
                    ${vars.breakpoints.xl} {
                      left: 0;
                    }
                  }
                }

                .quick-links-container {
                  bottom: 15px;
                }
              }
            }

            .hit-content {
              padding: 24px 16px 0;
              justify-content: start;

              .title {
                min-height: 50px;
                letter-spacing: -0.22px;
                ${vars.breakpoints.md} {
                  min-height: 62px;
                  font-size: 15px;
                }
                ${vars.breakpoints.lg} {
                  min-height: 62px;
                  font-size: ${vars.fonts.sizeH4};
                }
                ${vars.breakpoints.xl} {
                  min-height: 62px;
                }
                ${vars.breakpoints.xxl} {
                  min-height: 62px;
                  font-size: ${vars.fonts.sizeH8};
                }
              }

              .case {
                position: unset;
                width: 100%;
                transform: none;
                padding: 2px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                color: ${vars.colors.lwBodyBlack};
                ${vars.breakpoints.md} {
                  display: -webkit-box !important;
                  margin: 0 !important;
                }
                ${vars.breakpoints.lg} {
                  display: -webkit-box !important;
                  margin: 0 !important;
                }
                ${vars.breakpoints.xl} {
                  display: -webkit-box;
                  margin: 14.5px 0 16px !important;
                }
                ${vars.breakpoints.xxl} {
                  margin: 14.5px 0 16px !important;
                  display: -webkit-box;
                }
              }

              .web-description {
                height: auto;
                min-height: auto;
                margin: 14.5px 0 16px;
                padding: 0;
                font-size: ${vars.fonts.sizeH7};
                color: ${vars.colors.lwBodyBlack};
                ${vars.breakpoints.md} {
                  font-size: ${vars.fonts.sizeDefault};
                }
                ${vars.breakpoints.lg} {
                  font-size: ${vars.fonts.sizeDefault};
                  display: none;
                }
                ${vars.breakpoints.xl} {
                  font-size: ${vars.fonts.sizeDefault};
                  display: -webkit-box;
                  height: 39px;
                }
                ${vars.breakpoints.xxl} {
                  font-size: ${vars.fonts.sizeH4};
                  height: 44px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .inline-container {
                display: block;
                font-style: normal;

                .country-rating {
                  display: block;
                  margin: 0;
                  ${vars.breakpoints.md} {
                    margin-top: 0px;
                  }
                  ${vars.breakpoints.lg} {
                    margin-top: 0px;
                  }
                  ${vars.breakpoints.xl} {
                    margin-top: 8px;
                  }
                  ${vars.breakpoints.xxl} {
                    margin-top: 8px;
                  }

                  .country {
                    margin-bottom: 8px;
                    color: ${vars.colors.lwBodyBlack};
                    @media (max-width: 1024px) {
                      display: flex;
                      flex-direction: column;
                      row-gap: 5px;
                    }
                    ${vars.breakpoints.lg} {
                      margin-bottom: 2px;
                    }

                    .country-container {
                      img {
                        width: auto;
                        margin: 0 !important;
                      }
                    }

                    .grapeVariety-container {
                      ${vars.breakpoints.md} {
                        margin-left: 0;
                      }
                      ${vars.breakpoints.lg} {
                        margin-left: 12px;
                      }
                      ${vars.breakpoints.xl} {
                        margin-left: 12px;
                      }
                      ${vars.breakpoints.xxl} {
                        margin-left: 12px;
                      }
                    }
                  }

                  .rating {
                    margin-top: 0;
                    .review-count {
                      color: ${vars.colors.lwBodyBlack};
                      text-decoration: none;
                      &:hover {
                        text-decoration: none;
                      }
                    }

                    a {
                      cursor: pointer;
                      text-decoration: none;
                      &:hover {
                        text-decoration: none;
                      }
                    }
                  }
                }

                .reserveTodayContainer {
                  margin: 0 auto!important;
                  width: 100%;
                  > div {
                    padding: 8px;
                    border-radius: 4px;
                    ${vars.breakpoints.md} {
                      font-size: ${vars.fonts.sizeSmall};
                    }
                    ${vars.breakpoints.xl} {
                      margin: 18.5px 0 24px;
                      font-size: ${vars.fonts.sizeSmall};
                    }
                    ${vars.breakpoints.xxl} {
                      margin: 18.5px 0 28px;
                      font-size: ${vars.fonts.sizeH7};
                    }
                  }

                  .reserve-text {
                    font-weight: 400;
                  }
                  a {
                    text-decoration: underline;
                  }
                }
              }
            }

            .addToCart-container { 
              .price-section {
                margin-bottom: 10px;
                text-align: center;
                line-height: 26px;
                .sku-price {
                  font-family: ${vars.fonts.familyTertiary};
                  font-size: ${vars.fonts.sizeH5};
                  font-weight: ${vars.fonts.weightBold};
                  color: ${vars.colors.grey50};
                  .presell-price {
                    font-family: ${vars.fonts.familySecondary};
                    font-weight: ${vars.fonts.weightBold};
                    ${vars.breakpoints.md} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.lg} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xl} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xxl} { 
                      font-size: ${vars.fonts.sizeH5};
                    }
                  }
                  .presell-price + span {
                    ${vars.breakpoints.md} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.lg} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xl} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xxl} { 
                      font-size: ${vars.fonts.sizeH5};
                    }
                  }

                  .original-price-case {
                    padding: 0 5px;
                    font-size: ${vars.fonts.sizeLarge};
                    &.line-through {
                      text-decoration: line-through;
                      color: ${vars.colors.textSecondaryBg};
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightNormal};
                    }
                  }

                  .original-price-bottle {
                    ${vars.breakpoints.md} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.lg} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xl} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xxl} { 
                      font-size: ${vars.fonts.sizeH5};
                    }
                    .pricing-label {
                      font-size: ${vars.fonts.sizeLarge};
                    }
                  }

                  .vpp-price-section {
                    ${vars.breakpoints.md} { 
                      font-size: ${vars.fonts.sizeSmall};
                    }
                    ${vars.breakpoints.lg} { 
                      font-size: ${vars.fonts.sizeDefault};
                    }
                    ${vars.breakpoints.xl} { 
                      font-size: ${vars.fonts.sizeDefault};
                    }
                    ${vars.breakpoints.xxl} { 
                      font-size: ${vars.fonts.sizeDefault};
                    }

                    .sales-price {
                      color: ${vars.colors.textPrimaryBg};
                      font-size: ${vars.fonts.sizeDefault};
                      @media (max-width: 1024px) {
                        font-size: 13px;
                      }
                    }
                  }

                  .sales-price {
                    color: ${vars.colors.textPrimaryBg};
                    ${vars.breakpoints.md} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.lg} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xl} {
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xxl} { 
                      font-size: ${vars.fonts.sizeH5};
                    }
                  }

                  .pricing-label {
                    font-size: ${vars.fonts.sizeLarge};
                    font-weight: ${vars.fonts.weightNormal};
                    font-family: ${vars.fonts.familySecondary};
                  }
                  .mix-price-label {
                    font-size: ${vars.fonts.sizeLarge};
                  }
                  .vpp-price-label {
                    font-size: ${vars.fonts.sizeDefault};
                  }
                }
                .member-price-section-case {
                  margin-bottom: 10px;
                  font-family: ${vars.fonts.familySecondary};
                  font-weight: ${vars.fonts.weightBold};
                  color: ${vars.colors.textUnlimitedPrice};
                  font-size: ${vars.fonts.sizeDefault};

                  .unlimited-icon {
                    padding-right: 7px;
                    font-size: 20px;
                  }
                  .pricing-label {
                    font-weight: ${vars.fonts.weightNormal};
                  }
                  .member-price-label {
                    display: block;
                    ${vars.breakpoints.xxl} { 
                      display:inline;
                    }
                  }
                }

                .member-price-section-bottle {
                  margin-bottom: 10px;
                  
                  font-size: ${vars.fonts.sizeSmall};
                  font-family: ${vars.fonts.familySecondary};
                  font-weight: ${vars.fonts.weightBold};
                  color: ${vars.colors.textUnlimitedPrice};
                  ${vars.breakpoints.lg} {
                    font-size: ${vars.fonts.sizeDefault};
                  }
                  ${vars.breakpoints.xl} {
                    font-size: ${vars.fonts.sizeDefault};
                  }
                  ${vars.breakpoints.xxl} {
                    font-size: ${vars.fonts.sizeDefault};
                  }
                  .unlimited-icon {
                    padding-right: 7px;
                    font-size: 20px;
                  }
                  .pricing-label {
                    font-weight: ${vars.fonts.weightNormal};
                  }
                  .member-price-label {
                    ${vars.breakpoints.md} {
                      font-size: ${vars.fonts.sizeSmall};
                    }
                    ${vars.breakpoints.lg} {
                      font-size: ${vars.fonts.sizeDefault};
                    }
                    ${vars.breakpoints.xl} {
                      font-size: ${vars.fonts.sizeDefault};
                    }
                    ${vars.breakpoints.xxl} {
                      font-size: ${vars.fonts.sizeDefault};
                    }
                  }
                }
              }

              .add-to-cart-btn-container {
                margin: 0 auto;
                ${vars.breakpoints.md} {
                  width: 90%;
                }
                ${vars.breakpoints.lg} {
                  width: 83.5%;
                }
                ${vars.breakpoints.xl} {
                  width: 71%;
                }
                ${vars.breakpoints.xxl} {
                  width: 100%;
                }

                .tooltip-btn {
                  display: inline-block;
                  height: auto;
                  font-size: ${vars.fonts.sizeDefault};
                  border-radius: 5px;
                  line-height: 26px;
                  padding: 12px 24px;
                  border-width: 0;
                  border-style: solid;
                  background-color: ${vars.colors.btnsPrimaryBg};
                  color: ${vars.colors.lwJoinWhite};
                  border-width: 2px;
                  border-color: transparent;
                }
              }
            }
          }
        }
      }
      `,
    },
    Product: {
      wrapper: `  
      ${buildThemeWidget.Product.wrapper}
      .product-layout{
        .layout-name{
           .sub-title{
            font-weight: 600px;
            font-size: ${vars.fonts.sizeH8};
            line-height: 26.4px;
            font-family: ${vars.fonts.familyTertiary};
           }
            .main-title{
              font-size:48px;
              font-family:${vars.fonts.familyPrimary};
              line-height: 50.4px;
            }
        }
        .layout-details{
          .description-container{
           font-weight: ${vars.fonts.weightSmall};
            font-size: ${vars.fonts.sizeLarge};
            line-height: 22.4px;
            font-family: ${vars.fonts.familySecondary};
          }
        }
        .money-back-guarantee {
          flex-direction: column;
          gap: 10px;
          &.apac {
              justify-content: space-evenly;
              margin-left: -15px;
              margin-right: -15px;
              ${vars.breakpoints.sm} {
                margin-left: 0px;
                margin-right: 0px;
              }
              .inner-container {
                display: flex;
                align-items: center;
                .icon {
                  margin-right: 10px;
                  i {
                    color: #112B4B;
                  }
                }
                .content {
                  .descp {
                    padding-bottom: 0px;
                    color: #112B4B;
                  }
                }
              }
          }
          ${vars.breakpoints.md} {
            flex-direction: row;
            gap: 0px;
          }
        }
      }
      `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsAPACLW, buildThemeMixinsAPACLW, buildThemeWidgetStylesAPACLW);

export default createTheme;
